var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"transparent",attrs:{"outlined":"","tile":"","loading":_vm.loading}},[_c('v-card-title',[_vm._v(" Company Settings ")]),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_vm._l((_vm.settings),function(setting){return _c('v-row',{key:setting.id,staticClass:"pt-2"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_vm._v(_vm._s(setting.description))]),_c('v-col',{attrs:{"cols":"12","sm":"8"}},[(setting.type == 'bool')?_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"hide-details":"auto"},model:{value:(setting.value),callback:function ($$v) {_vm.$set(setting, "value", $$v)},expression:"setting.value"}}):(setting.type == 'string')?_c('v-text-field',{staticClass:"mt-0 pt-0",attrs:{"hide-details":"auto"},model:{value:(setting.value),callback:function ($$v) {_vm.$set(setting, "value", $$v)},expression:"setting.value"}}):(setting.type == 'decimal')?[(
                      setting.description
                        .toLowerCase()
                        .indexOf('percentage') > -1
                    )?_c('v-slider',{staticClass:"align-center",attrs:{"thumb-label":"always","thumb-size":24,"step":"0.01","max":"2","min":"0","hide-details":"auto","rules":[_vm.rules.positive, _vm.rules.percentage1]},model:{value:(setting.value),callback:function ($$v) {_vm.$set(setting, "value", $$v)},expression:"setting.value"}}):_c('v-text-field',{staticClass:"mt-0 pt-0",attrs:{"step":"0.01","hide-details":"auto","rules":[_vm.rules.required, _vm.rules.positive],"type":"number"},model:{value:(setting.value),callback:function ($$v) {_vm.$set(setting, "value", $$v)},expression:"setting.value"}})]:(setting.type == 'int')?[(
                      setting.description
                        .toLowerCase()
                        .indexOf('percentage') > -1
                    )?_c('v-slider',{staticClass:"align-center",attrs:{"thumb-label":"always","max":"200","min":"0","hide-details":"auto","rules":[_vm.rules.positive, _vm.rules.percentage100]},model:{value:(setting.value),callback:function ($$v) {_vm.$set(setting, "value", $$v)},expression:"setting.value"}}):_c('v-text-field',{staticClass:"mt-0 pt-0",attrs:{"hide-details":"auto","type":"number"},model:{value:(setting.value),callback:function ($$v) {_vm.$set(setting, "value", $$v)},expression:"setting.value"}})]:(setting.type == 'timespan')?_c('v-text-field',{staticClass:"mt-0 pt-0",attrs:{"rules":[_vm.rules.required],"pattern":"^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$","type":"time","hide-details":"auto"},model:{value:(setting.value),callback:function ($$v) {_vm.$set(setting, "value", $$v)},expression:"setting.value"}}):_vm._e()],2)],1)}),_c('div',[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"width":"100%","color":"primary","loading":_vm.saving},on:{"click":_vm.saveSettings}},[_vm._v(" Opslaan ")])],1)],1),_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"width":"100%","color":"secondary","loading":_vm.downloading},on:{"click":_vm.getCsvExportForConnectorLog}},[_vm._v(" Csv export voor connector log ")])],1)],1)],1)],2)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"multi-line":true,"color":_vm.snackbarSuccess ? 'light-green' : 'red'},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.saveSnackbar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}]),model:{value:(_vm.saveSnackbar),callback:function ($$v) {_vm.saveSnackbar=$$v},expression:"saveSnackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }