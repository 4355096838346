import axios from "axios";
// import settings from "../../cfg/settings";
const baseUrl = window.__env.baseURL;

const apiClient = axios.create({
  baseURL: baseUrl + "CompanySettings",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  ////////////////////////////// CompanySettings //////////////////////////////
  getCompanySettings() {
    return apiClient.get("/Get");
  },
  saveCompanySettings(companySettings) {
    return apiClient.post("/Save", companySettings);
  },
  ////////////////////////////// connector log //////////////////////////////
  getCsvExportForConnectorLog() {
    return apiClient({
      url: "/GetCsvExportForConnectorLog",
      method: "GET",
      responseType: "blob",
    });
  },
};
