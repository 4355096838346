<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="transparent" outlined tile :loading="loading">
          <v-card-title> Company Settings </v-card-title>
          <v-card-text>
            <!-- {{ settings }} -->
            <v-form v-model="valid" ref="form">
              <v-row v-for="setting in settings" :key="setting.id" class="pt-2">
                <v-col cols="12" sm="4">{{ setting.description }}</v-col>
                <v-col cols="12" sm="8">
                  <v-checkbox
                    v-if="setting.type == 'bool'"
                    v-model="setting.value"
                    hide-details="auto"
                    class="mt-0 pt-0"
                  />
                  <v-text-field
                    v-else-if="setting.type == 'string'"
                    v-model="setting.value"
                    hide-details="auto"
                    class="mt-0 pt-0"
                  />
                  <template v-else-if="setting.type == 'decimal'">
                    <v-slider
                      v-if="
                        setting.description
                          .toLowerCase()
                          .indexOf('percentage') > -1
                      "
                      v-model="setting.value"
                      class="align-center"
                      thumb-label="always"
                      :thumb-size="24"
                      step="0.01"
                      max="2"
                      min="0"
                      hide-details="auto"
                      :rules="[rules.positive, rules.percentage1]"
                    >
                    </v-slider>
                    <v-text-field
                      v-else
                      v-model="setting.value"
                      step="0.01"
                      hide-details="auto"
                      :rules="[rules.required, rules.positive]"
                      type="number"
                      class="mt-0 pt-0"
                    />
                  </template>

                  <template v-else-if="setting.type == 'int'">
                    <v-slider
                      v-if="
                        setting.description
                          .toLowerCase()
                          .indexOf('percentage') > -1
                      "
                      v-model="setting.value"
                      class="align-center"
                      thumb-label="always"
                      max="200"
                      min="0"
                      hide-details="auto"
                      :rules="[rules.positive, rules.percentage100]"
                    >
                    </v-slider>
                    <v-text-field
                      v-else
                      v-model="setting.value"
                      hide-details="auto"
                      type="number"
                      class="mt-0 pt-0"
                    />
                  </template>
                  <v-text-field
                    v-else-if="setting.type == 'timespan'"
                    :rules="[rules.required]"
                    v-model="setting.value"
                    pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                    type="time"
                    hide-details="auto"
                    class="mt-0 pt-0"
                  />
                </v-col>
              </v-row>
              <div>
                <v-row class="mt-2">
                  <v-col cols="12">
                    <v-btn
                      width="100%"
                      color="primary"
                      @click="saveSettings"
                      :loading="saving"
                    >
                      Opslaan
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="mt-1">
                  <v-col cols="12">
                    <v-btn
                      width="100%"
                      color="secondary"
                      @click="getCsvExportForConnectorLog"
                      :loading="downloading"
                    >
                      Csv export voor connector log
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-card-text>
          <!-- {{ settings }} -->
        </v-card>
      </v-col>
    </v-row>
    <!-- snackbar on save -->
    <v-snackbar
      v-model="saveSnackbar"
      :multi-line="true"
      :color="snackbarSuccess ? 'light-green' : 'red'"
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="saveSnackbar = false">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import services from "../../services/companySettings.js";

export default {
  created() {
    services
      .getCompanySettings()
      .then((response) => {
        // console.log(response);
        let tempSettings = response.data;
        // let ture = tempSettings
        //   .filter((setting) => setting.type == "bool")
        //   .forEach(
        //     (element) =>
        //       (element.value = Boolean(element.value == "true" ? true : false))
        //   );
        // console.log(ture);
        // console.log(Boolean("false"));
        this.settings = tempSettings;
      })
      .catch((error) => {
        //this.$store.commit("SAVE_ERROR", { error });
        console.log("There are errors: ", error.response);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  data() {
    return {
      loading: true,
      saving: false,
      downloading: false,
      valid: true,
      settings: [],
      rules: {
        required: (v) => !!v || "This field is required",
        positive: (v) => (v && v >= 0) || "This field has to be positive",
        percentage100: (v) => (v && v <= 200) || "Has to be a percentage.",
        percentage1: (v) => (v && v <= 2) || "Has to be a percentage.",
      },
      saveSnackbar: false,
      snackbarSuccess: true,
      snackbarText: "",
    };
  },
  methods: {
    saveSettings() {
      if (this.valid) {
        this.saving = true;
        let companySettings = [];

        companySettings = this.settings.map((x) => {
          let copied = {};
          copied.id = x.id;
          copied.type = x.type;
          copied.description = x.description;
          copied.code = x.code;
          copied.value = x.value.toString();
          copied.sortKey = x.sortKey;
          return copied;
        });

        services
          .saveCompanySettings(companySettings)
          .then((response) => {
            // console.log(response);
            //this.settings = response.data;
            if (response.data) {
              this.snackbarSuccess = true;
              this.snackbarText = "Opslaan was succesvol.";
              this.saveSnackbar = true;
            }
          })
          .catch((error) => {
            this.snackbarSuccess = false;
            this.snackbarText =
              "Er was een probleem, gelieve opnieuw te proberen. \n" +
              error.response.data.detail;
            this.saveSnackbar = true;
            console.log(error.response.data.title);
          })
          .finally(() => {
            this.saving = false;
          });
      } else {
        this.snackbarSuccess = false;
        this.snackbarText = "Gelieve alles correct in te vullen.";
        this.saveSnackbar = true;
      }
    },
    getCsvExportForConnectorLog() {
      this.downloading = true;
      services
        .getCsvExportForConnectorLog()
        .then((response) => {
          var fileName = response.headers["filename"];
          var objectUrl = window.URL.createObjectURL(
            new File([response.data], fileName, {
              type: response.headers["content-type"],
            })
          );
          var link = document.createElement("a");
          link.href = objectUrl;
          link.download = fileName;
          link.click();

          if (response.data) {
            this.snackbarSuccess = true;
            this.snackbarText = "File is gedownload.";
            this.saveSnackbar = true;
          }
        })
        .catch((error) => {
          this.snackbarSuccess = false;
          this.snackbarText =
            "Er was een probleem, gelieve opnieuw te proberen. \n" +
            error.response.data.detail;
          this.saveSnackbar = true;
          console.log(error.response.data.title);
        })
        .finally(() => {
          this.downloading = false;
        });
    },
  },
};
</script>

<style scoped></style>
